import React, {Component} from 'react';

/** 首页 **/
import IndexPage from './indexPage/index';

//后台账号
import AccountList from '../main/navigationPages/accountManage/AccountList';

//角色管理
import RoleList from '../main/navigationPages/roleManage/RoleList';
import AddRole from '../main/navigationPages/roleManage/AddRole';

//商家管理
import BusinessList from '../main/navigationPages/businessManage/BusinessList';

//用户管理
import UserList from '../main/navigationPages/userManage/UserList';

//新闻管理
import NewsList from '../main/navigationPages/newsManage/NewsList';
import AddNews from '../main/navigationPages/newsManage/AddNews';
import AllNewsList from '../main/navigationPages/newsManage/AllNewsList';

//违禁词
import SenWordsManage from '../main/navigationPages/senWordsManage/SenWordsManage';

//举报
import AppUserReportList from "../main/navigationPages/reportMsgManage/AppUserReportList";

//发现管理
import DiscoverList from "../main/navigationPages/DiscoverManage/DiscoverList";
import DiscoverDetail from "../main/navigationPages/DiscoverManage/DiscoverDetail";



export default {
    getView(props) {
        return [
            {
                path: "IndexPage",
                component: <IndexPage {...props} />
            },
            {
                path: "AccountList",
                component: <AccountList {...props} />
            },
            {
                path: "RoleList",
                component: <RoleList {...props} />
            },
            {
                path: "AddRole",
                component: <AddRole {...props} />
            },
            {
                path: "BusinessList",
                component: <BusinessList {...props} />
            },
            {
                path: "UserList",
                component: <UserList {...props} />
            },
            {
                path: "NewsList",
                component: <NewsList {...props} />
            },
            {
                path: "AddNews",
                component: <AddNews {...props} />
            },
            {
                path: "AllNewsList",
                component: <AllNewsList {...props} />
            },
            {
                path: "SenWordsGroupList",
                component: <SenWordsManage {...props} />
            },
            {
                path: "AppUserReportList",
                component: <AppUserReportList {...props} />
            },
            {
                path: "DiscoverList",
                component: <DiscoverList {...props} />
            },
            {
                path: "DiscoverDetail",
                component: <DiscoverDetail {...props} />
            },
        ]
    }
};
