import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './DiscoverManage.less';
import APILXD from '../../../http/APILXD.js';
import {Button, message, Modal, Popover} from "antd";
import HttpTool from "../../../tool/HttpTool";

class DiscoverList extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        columns: this.getTableColumns(),
        url: APILXD.getAllDiscoverList,
        otherParam: null,
      },
      search: {
        seniorSearch: this.getSeniorSearch(),
        option: {
          placeholder: '输入发现关键词搜索',
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            return true;
          }
        }
      },
    }
  }


  getSeniorSearch(defaultData) {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[\S\s]{0,50}$/,
      verMessage: "最多50个字符",
      required: false,
    };
    return {
      colCount: 3,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 18, offset: 0},
      },
      parameterArr: [
        {
          ...defaultOption,
          field: "tag",
          name: "标签",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return true;
          },
          option: {
            allowClear: true
          },
          data: [
            {
              value: "杭州",
              title: "杭州"
            },
            {
              value: "分享生活",
              title: "分享生活",
            },
            {
              value: "租房那些事",
              title: "租房那些事"
            },
            {
              value: "求职那些事",
              title: "求职那些事"
            },
            {
              value: "职场爆料",
              title: "职场爆料"
            },
            {
              value: "相亲交友",
              title: "相亲交友"
            },
            {
              value: "宠物之家",
              title: "宠物之家"
            },
          ]
        },
        {
          ...defaultOption,
          field: "status",
          name: "状态",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return true;
          },
          option: {
            allowClear: true
          },
          data: [
            {
              title: <span style={{color: "limegreen"}}>正常展示</span>,
              value: 1
            },
            {
              title: <span style={{color: "red"}}>已屏蔽</span>,
              value: 2
            },
          ]
        },
        {
          ...defaultOption,
          field: "uid",
          name: "用户id",
          type: 'input',
          reg: (v) => {
            return true;
          },
          option: {
            allowClear: true
          },
        },
      ],
    }
  }

  changeStatus(id,status){
    let param = {
      _id:id,
      status,
    };
    let successCB = (code,msg,json,option)=>{
      this.setState({loading:false},()=>{
        message.success('操作成功');
        this._loadDataForNet();
      });
    };
    let failureCB = (code,msg)=>{
      this.setState({loading:false},()=>{
        message.error(msg);
      });
    };

    this.setState({loading:true});

    HttpTool.post(APILXD.setDiscoverStatus,successCB,failureCB,param);
  }


  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '标签',
        dataIndex: 'tag',
        key: 'tag',
      },
      {
        title: '用户',
        dataIndex: 'uid',
        key: 'uid',
        render: (text,record)=>{
          return <div>
            <div>{record.name}</div>
            <Popover title={"用户id"} content={record.uid}>
              <div style={{color: "dodgerblue", transform: "scale(0.8)"}}>查看id</div>
            </Popover>
          </div>
        }
      },
      {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: '内容',
        dataIndex: 'content',
        key: 'content',
        width: 400,
        render: (text,record)=>{
          return <div style={{maxHeight: "200px",overflow: "auto"}}>
            {text}
          </div>
        }
      },
      {
        title: '点赞数',
        dataIndex: 'likes',
        key: 'likes',
      },
      {
        title: '当前状态',
        dataIndex: 'status',
        key: 'status',
        render: (text)=>{
          return {
            1: <span style={{color: "limegreen"}}>正常显示</span>,
            2: <span style={{color: "red"}}>已屏蔽</span>,
          }[text]
        }
      },
      {
        title: '操作',
        width: 180,
        render: (text, record, index) => {
          return (<div>
            <div
              className={less.btnDetail}
              onClick={() => {
                this.props.openTab({
                  path: 'DiscoverDetail',
                  title: '发现详情',
                  post: {
                    parent: this.props,
                    _id: record._id
                  }
                })
              }}
            >详情
            </div>
            {
              this.powerConfig.Edit
                ?(  <div
                  className={record.status==1?less.btnDelete:less.btnEdit}
                  onClick={() => {
                    let newStatus = record.status==1?2:1;

                    if(newStatus === 1){
                      Modal.confirm({
                        title:<div>确定 <span style={{color:'limegreen'}}>正常展示</span> 这条发现动态 吗？</div>,
                        content:<div >
                          设置为<span style={{color: "limegreen"}}>正常展示</span>后，App端用户可以看到该动态。
                        </div>,
                        onOk:()=>{this.changeStatus(record._id,newStatus)}
                      })
                    }else{
                      Modal.confirm({
                        title:<div>确定 <span style={{color:'red'}}>屏蔽展示</span> 这条发现动态 吗？</div>,
                        content:<div>
                          设置为<span style={{color: "red"}}>屏蔽展示</span>后，App端用户将无法看到该动态。
                        </div>,
                        onOk:()=>{this.changeStatus(record._id,newStatus)}
                      })
                    }
                  }}
                >{record.status==1?'设置屏蔽':'设置展示'}
                </div>)
                :null
            }
          </div>)
        }
      },
    ]
  }
}

module.exports = DiscoverList;