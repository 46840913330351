import React from 'react';
import less from './SenWordsManage.less';
import SenWordsGroup from "./SenWordsGroup";
import SenWords from "./SenWords"
import {Empty} from "antd";

class SenWordsManage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      record: null
    }
  }

  render(){
    let {record} = this.state;

    return <div className={less.outer}>
      <div className={less.halfBox}>
        <SenWordsGroup
          {...this.props}
          manageWords={(data)=>{
            this.setState({
              record: data
            })
          }}
          onDeleteGroup={(_id)=>{
            if(record._id === _id){
              this.setState({
                record: null
              })
            }
          }}
        />
      </div>
      <div className={less.halfBox}>
        {
          record
            ?<SenWords
              key={record._id}
              record={record}
              {...this.props}
            />
            :<Empty
              description={"请从左侧选择需要管理的分组"}
              style={{marginTop: "100px"}}
            />
        }

      </div>
    </div>
  }
}

module.exports = SenWordsManage;