import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './ReportList.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import TimeHelp from "../../../tool/TimeHelp";
import {Button, message, Modal} from "antd";
import ModalBase from "../../../components/modalBase/index"

const ModalDelete = new ModalDel();
const MB = new ModalBase();

class AppUserReportList extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        columns: this.getTableColumns(),
        url: APILXD.getReportList,
        otherParam: null,
      },
      search: {
        seniorSearch: this.getSeniorSearch(),
        option: {
          placeholder: '输入举报关键词搜索',
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            return true;
          }
        }
      },
    }
  }


  getSeniorSearch(defaultData) {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[\S\s]{0,50}$/,
      verMessage: "最多50个字符",
      required: false,
    };
    return {
      colCount: 3,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 18, offset: 0},
      },
      parameterArr: [
        {
          ...defaultOption,
          field: "type",
          name: "分类",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return true;
          },
          option: {
            allowClear: true
          },
          data: [
            {
              value: "内容不适造成骚扰",
              title: "内容不适造成骚扰",
            },
            {
              value: "欺诈骗钱行为",
              title: "欺诈骗钱行为"
            },
            {
              value: "恶意操作行为",
              title: "恶意操作行为"
            },
            {
              value: "含未成年违禁内容",
              title: "含未成年违禁内容"
            },
            {
              value: "性别等歧视",
              title: "性别等歧视"
            },
            {
              value: "其他",
              title: "其他"
            },
          ]
        },
        {
          ...defaultOption,
          field: "status",
          name: "状态",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return true;
          },
          option: {
            allowClear: true
          },
          data: [
            {
              title: <span style={{color: "darkorange"}}>未处理</span>,
              value: 1
            },
            {
              title: <span style={{color: "limegreen"}}>已处理</span>,
              value: 2
            },
          ]
        },
      ],
    }
  }

  //处理问题
  dealReport(record){
    MB.show(
      {
        title: "处理举报",
        okTitle: "确定",
        closeTitle: "取消",
        width: "60%"
      },
      {
        colCount: 1,
        formItemLayout: {
          labelCol: {span: 4},
          wrapperCol: {span: 16, offset: 0},
        },
        parameterArr: [
          {
            type: "TextArea",
            ver: true,
            required: true,
            field: "reason",
            name: "反馈信息",
            reg: (v) => {
              return !!v;
            },
            verMessage: "请填写",
            option:{
              placeholder: "请填写处理结果",
              maxLength: 100,
              // placeholder: "请填写处理结果，该内容将作为系统消息反馈给举报人",
              autoSize: {
                minRows: 6,
                maxRows: 10
              }
            },
          },
        ],
      },
      {
        url: APILXD.solveReport,
        otherParam: {_id: record?._id},
        beforeSubmit: (param) => {

          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }

  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '分类',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: '描述',
        dataIndex: 'content',
        key: 'content',
        width: 400,
        render: (text,record)=>{
          return <div style={{maxHeight: "200px",overflow: "auto"}}>
            {text}
          </div>
        }
      },
      {
        title: '截图',
        dataIndex: 'imgs',
        key: 'imgs',
        render: (text,record)=>{
          if(!record?.imgs?.length){
            return "-"
          }
          // record.imgs = [
          //   "https://fwdevtvbox.oss-cn-shanghai.aliyuncs.com/3c48394f13f242f1873ca1a69008a398.png",
          //   "https://fwdevtvbox.oss-cn-shanghai.aliyuncs.com/a52312559c774cea86e126c90ef89819.png",
          //   "https://fwdevtvbox.oss-cn-shanghai.aliyuncs.com/3c48394f13f242f1873ca1a69008a398.png",
          //   "https://fwdevtvbox.oss-cn-shanghai.aliyuncs.com/b3e30d98c3b84dd28a56179768b24b1a.png",
          // ]
          return <div >
            <Button
              type={"link"}
              onClick={()=>{
                Modal.info({
                  title: "举报截图",
                  width: "80%",
                  content: <div className={less.imgViewModal}>
                    {record?.imgs?.map((url,index)=>{
                      return <img src={url} alt="" key={"i_"+index} className={less.imgView}/>
                    })}
                  </div>
                })
              }}
            >查看</Button>
          </div>
        }
      },
      {
        title: '创建时间',
        dataIndex: 'addtime',
        key: 'addtime',
        render:(text,record)=>{
          return <div>{TimeHelp.getYMDHM(record.addtime - 5000,true)}</div>
        }
      },
      {
        title: '当前状态',
        dataIndex: 'status',
        key: 'status',
        render: (text)=>{
          return {
            1: <span style={{color: "darkorange"}}>未处理</span>,
            2: <span style={{color: "limegreen"}}>已处理</span>,
          }[text]
        }
      },
      {
        title: '举报人名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '操作',
        width: 180,
        render: (text, record, index) => {
          return (<div>
            {
              this.powerConfig.Edit && record.status === 1
                ?(  <div
                  className={less.btnEdit}
                  onClick={() => {
                    this.dealReport(record)
                  }}
                >解决
                </div>)
                :null
            }
            {
              record.status === 2
                ?(  <div
                  className={less.btnDetail}
                  onClick={() => {
                    Modal.info({
                      title: "反馈结果",
                      content: record.reason
                    })
                  }}
                >查看处理结果
                </div>)
                :null
            }
            {/*{*/}
            {/*  this.powerConfig.Delete*/}
            {/*    ?(<div*/}
            {/*      className={less.btnDelete}*/}
            {/*      onClick={() => {*/}
            {/*        ModalDelete.show({*/}
            {/*            title: "提示",*/}
            {/*            okTitle: "确定",*/}
            {/*            closeTitle: "取消",*/}
            {/*          },*/}
            {/*          {}*/}
            {/*          ,*/}
            {/*          {*/}
            {/*            otherParam: {_id: record._id},*/}
            {/*            content: record.name,*/}
            {/*            url: "",*/}
            {/*            apiType: 'post',*/}
            {/*            tip: '删除后将无法恢复，请谨慎操作',*/}
            {/*            callBack: (state) => {*/}
            {/*              //删除成功回调*/}
            {/*              state === "success" && this._loadDataForNet();*/}
            {/*            }*/}
            {/*          });*/}
            {/*      }}*/}
            {/*    >删除*/}
            {/*    </div>)*/}
            {/*    :null*/}
            {/*}*/}
          </div>)
        }
      },
    ]
  }
}

module.exports = AppUserReportList;