import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './AccountManage.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import {Modal, message, Cascader, Space, Popover, Tag, Form, Input, ConfigProvider} from 'antd';
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index"
import HttpTool from "../../../tool/HttpTool";
import md5 from "md5";
import UploadFile from "../../../components/upLoad/UploadFile";
import zhCN from "antd/lib/locale/zh_CN";
import {findFullCodes} from "../../help/LXDHelp";

const MB = new ModalBase();
const ModalDelete = new ModalDel();

class AccountList extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        columns: this.getTableColumns(),
        url: APILXD.getAdminList,
        otherParam: null,
      },
      search: {
        seniorSearch: this.getSeniorSearch(),
        option: {
          openSenior: true,
          placeholder: '输入关键词搜索',
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            return true;
          }
        }
      },
      new: {
        defaultValue: '创建新账号',
        action: () => {
          this.addNewAccount()
        }
      }
    }
  }

  getSeniorSearch(defaultData) {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[\S\s]{0,50}$/,
      verMessage: "最多50个字符",
      required: false,
    };
    return {
      colCount: 3,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 18, offset: 0},
      },
      parameterArr: [
        {
          ...defaultOption,
          field: "status",
          name: "状态",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return true;
          },
          option: {
            allowClear: true
          },
          data: [
            {
              title: <span style={{color: "limegreen"}}>启用</span>,
              value: 1
            },
            {
              title: <span style={{color: "red"}}>禁用</span>,
              value: 2
            },
          ]
        },
        {
          ...defaultOption,
          field: "roleId",
          name: "角色",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return true;
          },
          apiConfig: {
            url: APILXD.getAllRole,
            fillObject: (json) => {
              let data = [];
              for (let obj of json) {
                data.push({
                  title: obj.name,
                  value: obj._id,
                });
              }
              return data;
            }
          },
          option: {
            allowClear: true
          },
          data: []
        },
      ],
    }
  }

  //创建、编辑账号
  addNewAccount() {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[a-zA-Z0-9]{6,12}$/,
      verMessage: "6-12位数字或英文字母",
      required: true,
    };
    MB.show(
      {
        title: "创建账号",
        okTitle: "确定",
        closeTitle: "取消",
        width: "60%"
      },
      {
        colCount: 1,
        formItemLayout: {
          labelCol: {span: 4},
          wrapperCol: {span: 11, offset: 0},
        },
        parameterArr: [
          {
            ...defaultOption,
            field: "name",
            name: "用户名称",
            reg: /^[\S\s]{1,10}$/,
            verMessage: "1-10个字符",
            option: {
              maxLength: 10,
            },
          },
          {
            ...defaultOption,
            field: "roleId",
            name: "角色",
            type: 'select',
            selectType: 'value',
            reg: (v) => {
              return !!v;
            },
            apiConfig: {
              url: APILXD.getAllRole,
              fillObject: (json) => {
                let data = [];
                for (let obj of json) {
                  data.push({
                    title: obj.name,
                    value: obj._id,
                  });
                }
                return data;
              }
            },
            option: {
              allowClear: true
            },
            data: []
          },
          {
            ...defaultOption,
            field: "account",
            name: "登录账号",
            reg: /^[a-zA-Z]{3,20}$/,
            option: {
              placeholder: '3-20位英文字符',
              maxLength: '20',
            }
          },
          {
            ...defaultOption,
            field: "passwd",
            name: "密码",
            reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
            verMessage: "6-12位数字或英文字母或特殊符号",
            option: {
              placeholder: '6-12位数字或英文字母',
              maxLength: '12',
              type: 'password',
            }
          },
          {
            ...defaultOption,
            field: "checkword",
            reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
            name: "确认密码",
            verMessage: "请核对密码",
            option: {
              placeholder: '再次输入密码',
              maxLength: '12',
              type: 'password',
            }
          },
        ],
      },
      {
        url: APILXD.addAdmin,
        otherParam: {},
        beforeSubmit: (param) => {
          if (param.passwd != param.checkword) {
            message.warning('两次密码输入不一致，请检查');
            return false;
          }
          //加密
          param.passwd = md5(param.passwd);

          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }

  editAccount(data) {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[a-zA-Z0-9]{6,12}$/,
      verMessage: "6-12位数字或英文字母",
      required: true,
    };
    MB.show(
      {
        title: "编辑账号",
        okTitle: "确定",
        closeTitle: "取消",
        width: "60%"
      },
      {
        colCount: 1,
        formItemLayout: {
          labelCol: {span: 4},
          wrapperCol: {span: 11, offset: 0},
        },
        parameterArr: [
          {
            ...defaultOption,
            field: "name",
            name: "用户名称",
            reg: /^[\S\s]{1,10}$/,
            verMessage: "1-10个字符",
            option: {
              maxLength: 10,
              defaultValue: data.name
            },
          },
          {
            ...defaultOption,
            field: "roleId",
            name: "角色",
            type: 'select',
            selectType: 'value',
            reg: (v) => {
              return !!v;
            },
            apiConfig: {
              url: APILXD.getAllRole,
              fillObject: (json) => {
                let data = [];
                for (let obj of json) {
                  data.push({
                    title: obj.name,
                    value: obj._id,
                  });
                }
                return data;
              }
            },
            option: {
              allowClear: true,
              defaultValue: {
                key: data.roleId,
                value: data.roleId
              }
            },
            data: []
          },
          {
            ...defaultOption,
            field: "account",
            name: "登录账号",
            reg: /^[a-zA-Z0-9]{3,20}$/,
            option: {
              placeholder: '3-20位英文字符',
              maxLength: '20',
              defaultValue: data.account
            }
          },
        ],
      },
      {
        url: APILXD.editAdmin,
        otherParam: {
          _id: data._id
        },
        beforeSubmit: (param) => {


          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }

  changePW(data) {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[a-zA-Z0-9]{6,12}$/,
      verMessage: "6-12位数字或英文字母",
      required: true,
    };
    MB.show(
      {
        title: "重置密码",
        okTitle: "确定",
        closeTitle: "取消",
        width: "60%"
      },
      {
        colCount: 1,
        formItemLayout: {
          labelCol: {span: 4},
          wrapperCol: {span: 11, offset: 0},
        },
        parameterArr: [
          {
            ...defaultOption,
            field: "name",
            name: "用户名称",
            reg: /^[\S\s]{1,10}$/,
            verMessage: "1-10个字符",
            purpose: "see",
            option: {
              maxLength: 10,
              defaultValue: data.name,
            },
          },
          {
            ...defaultOption,
            field: "account",
            purpose: "see",
            name: "登录账号",
            reg: /^[a-zA-Z]{3,20}$/,
            option: {
              placeholder: '3-20位英文字符',
              maxLength: '20',
              defaultValue: data.account,
            }
          },
          {
            ...defaultOption,
            field: "passwd",
            name: "密码",
            reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
            verMessage: "6-12位数字或英文字母或特殊符号",
            option: {
              placeholder: '6-12位数字或英文字母',
              maxLength: '12',
              type: 'password',
            }
          },
          {
            ...defaultOption,
            field: "checkword",
            reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
            name: "确认密码",
            verMessage: "请核对密码",
            option: {
              placeholder: '再次输入密码',
              maxLength: '12',
              type: 'password',
            }
          },
        ],
      },
      {
        url: APILXD.resetPasswd,
        otherParam: {_id: data._id},
        beforeSubmit: (param) => {
          if (param.passwd != param.checkword) {
            message.warning('两次密码输入不一致，请检查');
            return false;
          }
          //加密
          param.passwd = md5(param.passwd);

          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }

  changeAccountStatus(id, status) {
    let param = {
      _id: id,
      status,
    };
    let successCB = (code, msg, json, option) => {
      this.setState({loading: false}, () => {
        message.success('操作成功');
        this._loadDataForNet();
      });
    };
    let failureCB = (code, msg) => {
      this.setState({loading: false}, () => {
        message.error(msg);
      });
    };

    this.setState({loading: true});

    HttpTool.post(APILXD.enableAdmin, successCB, failureCB, param);
  }

  setAccountAdCode(params) {
    let successCB = (code, msg, json, option) => {
      this.setState({loading: false}, () => {
        message.success('操作成功');
        this._loadDataForNet();
      });
    };
    let failureCB = (code, msg) => {
      this.setState({loading: false}, () => {
        message.error(msg);
      });
    };

    this.setState({loading: true});

    HttpTool.post(APILXD.setAdCode, successCB, failureCB, params);
  }

  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '用户名称',
        dataIndex: 'name',
        key: 'name',
        render: (txt, record) => {
          let full = findFullCodes(record.adcode)
          // console.log("full",full)
          let last = full[full.length - 1];

          return <Space>
            <div>{txt}</div>
            {
              last
                ? <Popover
                  title={"新闻覆盖地区"}
                  content={full.map((each) => {
                    return each.label
                  }).join("/")}
                >
                  <Tag
                    color={last.value === "100000" ? "magenta" : "blue"}
                  >{last.label}</Tag>
                </Popover>
                : null
            }
          </Space>
        }
      },
      {
        title: '机构',
        dataIndex: 'institution',
        key: 'institution',
        render: (text, record) => {
          if(!record.logo && !text){
            return  "-"
          }

          return <Space>
            {
              record.logo
                ? <div
                  className={less.icon}
                  style={{backgroundImage: `url(${record.logo})`}}
                ></div>
                : null
            }
            <div>{text}</div>
          </Space>
        }
      },
      {
        title: '登录账号',
        dataIndex: 'account',
        key: 'account',
      },
      {
        title: '所属角色',
        dataIndex: 'roleName',
        key: 'roleName',
      },
      {
        title: '创建人',
        dataIndex: 'admin',
        key: 'admin',
        render: (text, record) => {
          return record?.admin?.name || record?.admin?._id || "*"
        }
      },
      {
        title: '创建时间',
        dataIndex: 'addtime',
        key: 'addtime',
        render: (text, record) => {
          return <div>{TimeHelp.getYMDHM(record.addtime)}</div>
        }
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
          return text == 1 ? <span style={{color: 'limegreen'}}>启用</span> : <span style={{color: 'red'}}>禁用</span>
        }
      },
      {
        title: '操作',
        width: 350,
        render: (text, record, index) => {
          return (<div>
            {
              this.powerConfig.Edit
                ? (<div
                  className={record.status == 1 ? less.btnDelete : less.btnEdit}
                  onClick={() => {
                    let newStatus = record.status == 1 ? 2 : 1;

                    if (newStatus === 1) {
                      Modal.confirm({
                        title: <div>确定 <span style={{color: 'limegreen'}}>启用</span> "{record.name}" 吗？</div>,
                        content: <div style={{color: "red"}}>
                          启用后该系统账号将正常使用，请谨慎操作。
                        </div>,
                        onOk: () => {
                          this.changeAccountStatus(record._id, newStatus)
                        }
                      })
                    } else {
                      Modal.confirm({
                        title: <div>确定 <span style={{color: 'red'}}>禁用</span> "{record.name}" 吗？</div>,
                        content: <div style={{color: "red"}}>
                          禁用后该系统账号将无法使用，请谨慎操作。
                        </div>,
                        onOk: () => {
                          this.changeAccountStatus(record._id, newStatus)
                        }
                      })
                    }
                  }}
                >{record.status == 1 ? '禁用' : '启用'}
                </div>)
                : null
            }
            {
              record.newsManage === 1
                ? (<div
                  className={less.btnEdit}
                  onClick={() => {
                    if (!window._areaFormat?.length) {
                      message.info("区域数据加载中，请稍后再尝试。")
                      return;
                    }

                    let defaultValue = findFullCodes(record.adcode).map((item) => {
                      return item.value
                    })

                    let defaultData = {
                      adcodeSet: defaultValue,
                      institution: record.institution,
                      logo: record.logo,
                    }

                    let imgMaps = {};

                    if (record.logo) {
                      imgMaps[record.logo] = true;
                    }

                    Modal.confirm({
                      title: "配置数据权限",
                      closable: true,
                      maskClosable: false,
                      width: "800px",
                      content: <div>
                        <ConfigProvider
                          locale={zhCN}
                        >
                          <Form
                            ref={ref => this.modalForm = ref}
                            initialValues={defaultData}
                            labelCol={{span: 4}}
                            wrapperCol={{span: 16}}
                          >
                            <Form.Item
                              label={"机构名称"}
                              name={"institution"}
                              rules={[{required: true}]}
                            >
                              <Input
                                style={{width: "100%"}}
                                placeholder={"请填写机构名称"}
                                maxLength={10}
                              />
                            </Form.Item>
                            <Form.Item
                              label={"Logo"}
                              name={"logo"}
                              rules={[{required: true}]}
                              normalize={(value, prevValue, prevValues) => {
                                if (value?.[0]) {
                                  imgMaps[value[0].url] = true;

                                  return value[0].url
                                } else {
                                  return null
                                }
                              }}
                            >
                              <UploadFile
                                accept={"image/*"}
                                data={{
                                  option: {
                                    size: 1,
                                    defaultValue: defaultData.logo ? [{url: defaultData.logo}] : null
                                  }
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              label={"选择覆盖地区"}
                              name={"adcodeSet"}
                              rules={[{required: true}]}
                            >
                              <Cascader
                                defaultValue={defaultValue}
                                showSearch={true}
                                placeholder={"选择地区"}
                                changeOnSelect
                                style={{width: "100%"}}
                                // expandTrigger={"hover"}
                                options={window._areaFormat}
                              />
                            </Form.Item>

                          </Form>
                        </ConfigProvider>,
                      </div>,
                      onOk: () => {
                        return new Promise((resolve, reject) => {
                          this.modalForm.validateFields().then((values) => {
                            let params = _.cloneDeep(values)

                            let delImgs = [];

                            Object.keys(imgMaps).forEach((url) => {
                              if (url !== params.logo) {
                                delImgs.push(url)
                              }
                            })

                            params.adcode = params.adcodeSet.pop();
                            params.delFiles = delImgs;
                            params._id = record._id;

                            this.setAccountAdCode(params)
                            resolve()
                          }).catch((err) => {
                            reject()
                          })
                        })
                      }
                    })
                  }}
                >数据权限
                </div>)
                : null
            }
            {
              this.powerConfig.Edit
                ? (<div
                  className={less.btnEdit}
                  onClick={() => {
                    this.editAccount(record)
                  }}
                >编辑
                </div>)
                : null
            }
            {
              this.powerConfig.Edit
                ? (<div
                  className={less.btnEdit}
                  onClick={() => {
                    this.changePW(record)
                  }}
                >重置密码
                </div>)
                : null
            }
            {
              this.powerConfig.Delete
                ? (<div
                  className={less.btnDelete}
                  onClick={() => {
                    ModalDelete.show({
                        title: "提示",
                        okTitle: "确定",
                        closeTitle: "取消",
                      },
                      {}
                      ,
                      {
                        otherParam: {_id: record._id},
                        content: record.name,
                        url: APILXD.delAdmin,
                        apiType: 'post',
                        tip: '删除后将无法恢复，请谨慎操作',
                        callBack: (state) => {
                          //删除成功回调
                          state === "success" && this._loadDataForNet();
                        }
                      });
                  }}
                >删除
                </div>)
                : null
            }
          </div>)
        }
      },
    ]
  }
}

module.exports = AccountList;