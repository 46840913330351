//这个function是在服务器不支持权限配置的时候，为了前端结构不做大的修改，用于作为默认权限。
let functions = [
    {
        title: '查看',
        name: 'Query',
        permit: true,
        key: 'TestQuery',
        url: null,
    },
    {
        title: '新增',
        name: 'New',
        permit: true,
        key: 'TestNew',
        url: null,
    },
    {
        title: '编辑',
        key: 'TestEdit',
        permit: true,
        name: 'Edit',
        url: null,
    },
    {
        title: '删除',
        name: 'Delete',
        permit: true,
        key: 'TestDelete',
        url: null,
    }
];

//服务端有权限配置，填{}，代码执行时会被替换。否则把上面的functions填入作为默认权限
//同时leftMenu/Index.js里  componentDidMount中menuData数据不再需要checkPower
//配置了 openType:activeFirst 的重新打开时会先判断是否已经存在了，如果存在，则直接激活菜单。

//左侧导航栏配置 type:1:父栏(用于展开) 2:子栏(可跳转，需配置url)
module.exports = [
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'NewsManage',
        index: 5,
        name: '新闻管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: null,
        id: 'NewsList',
        index: 1,
        name: '我的新闻',
        parentId: 'NewsManage',
        type: 2,
        url: 'NewsList',
    },
    {
        functions: {},
        icon: null,
        id: 'AllNewsList',
        index: 2,
        name: '所有新闻',
        parentId: 'NewsManage',
        type: 2,
        url: 'AllNewsList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'UserManage',
        index: 10,
        name: '用户管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: null,
        id: 'UserList',
        index: 1,
        name: '用户列表',
        parentId: 'UserManage',
        type: 2,
        url: 'UserList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'BusinessManage',
        index: 15,
        name: '商家管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: null,
        id: 'BusinessList',
        index: 1,
        name: '商家列表',
        parentId: 'BusinessManage',
        type: 2,
        url: 'BusinessList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'DiscoverManage',
        index: 18,
        name: '发现管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: null,
        id: 'DiscoverList',
        index: 1,
        name: '发现列表',
        parentId: 'DiscoverManage',
        type: 2,
        url: 'DiscoverList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'SenWordsManage',
        index: 22,
        name: '违禁词管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: null,
        id: 'SenWordsGroupList',
        index: 1,
        name: '违禁词分组列表',
        parentId: 'SenWordsManage',
        type: 2,
        url: 'SenWordsGroupList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'ReportMsgManage',
        index: 23,
        name: '举报反馈管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: null,
        id: 'AppUserReportList',
        index: 1,
        name: 'APP用户举报',
        parentId: 'ReportMsgManage',
        type: 2,
        url: 'AppUserReportList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'AccountManage',
        index: 25,
        name: '系统账号管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: null,
        id: 'AccountList',
        index: 1,
        name: '系统账号列表',
        parentId: 'AccountManage',
        type: 2,
        url: 'AccountList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'RoleManage',
        index: 30,
        name: '权限管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: null,
        id: 'RoleList',
        index: 1,
        name: '角色管理',
        parentId: 'RoleManage',
        type: 2,
        url: 'RoleList',
    },
];
