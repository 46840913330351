import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './SenWordsManage.less';
import APILXD from '../../../http/APILXD.js';
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index"
const MB = new ModalBase();
import {Button, message, Modal} from "antd";
import HttpTool from "../../../tool/HttpTool";

class SenWords extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        title: this.props.record?.name + " 管理",
        columns: this.getTableColumns(),
        url: APILXD.getSenGroupWordsList,
        otherParam: {
          sgroupId: this.props.record?._id
        },
        couldSelect: true
      },
      search: {
        option: {
          placeholder: '输入违禁词搜索',
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            return true;
          }
        }
      },
      new: {
        defaultValue: '新增违禁词',
        action: () => {
          this.addNewWords()
        }
      }
    }
  }

  _getExtraBox(){
    return <div style={{float: "right", marginLeft: "20px"}}>
      <Button
        type={"danger"}
        onClick={()=>{
          this.delWords()
        }}
      >删除勾选违禁词</Button>
      <Button
        style={{marginLeft: "20px"}}
        type={"danger"}
        onClick={()=>{
          this.clearWords()
        }}
      >清空违禁词</Button>
    </div>
  }

  delWords(){
    // console.log(this.state.selectedRowKeys)

    Modal.confirm({
      title: "提示",
      content: "是否删除勾选的" + this.state.selectedRowKeys?.length + " 个违禁词？",
      okText: "确定删除",
      onOk: ()=>{
        HttpTool.post(APILXD.delSenWords, (code, msg) => {
          message.success(msg)
          this._loadDataForNet()
        }, (code, msg) => {
          message.error(msg)
        }, {
          sgroupId: this.props.record?._id,
          ids: this.state.selectedRowKeys
        })
      }
    })
  }

  clearWords(){
    Modal.confirm({
      title: "提示",
      content: `是否清空【${this.props.record?.name}】下所有违禁词？`,
      okText: "确定清空",
      onOk: ()=>{
        HttpTool.post(APILXD.emptySenGroupWords, (code, msg) => {
          message.success(msg)
          this._loadDataForNet()
        }, (code, msg) => {
          message.error(msg)
        }, {
          sgroupId: this.props.record?._id,
        })
      }
    })
  }

  addNewWords(){
    MB.show(
      {
        title: "新增违禁词",
        okTitle: "确定",
        closeTitle: "取消",
        width: "60%"
      },
      {
        colCount: 1,
        formItemLayout: {
          labelCol: {span: 4},
          wrapperCol: {span: 16, offset: 0},
        },
        parameterArr: [
          {
            type: "TextArea",
            ver: true,
            required: true,
            field: "nameList",
            name: "违禁词",
            reg: (v) => {
              return !!v;
            },
            verMessage: "请填写",
            option:{
              placeholder: "多个违禁词用换行或者逗号分割",
              autoSize: {
                minRows: 6,
                maxRows: 10
              }
            },
          },
        ],
      },
      {
        url: APILXD.addSenWords,
        otherParam: {sgroupId: this.props.record?._id},
        beforeSubmit: (param) => {

          if (!param.nameList || !_.trim(param.nameList)) {
            message.warn("至少填写一个违禁词")
            return false;
          }

          let arr= _.trim(param.nameList).split(/[，,\r\n]/).filter((v)=>{
            return !!v
          });

          param.words = arr;
          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }


  editWords(data){
    MB.show(
      {
        title: "编辑违禁词",
        okTitle: "确定",
        closeTitle: "取消",
        width: "60%"
      },
      {
        colCount: 1,
        formItemLayout: {
          labelCol: {span: 4},
          wrapperCol: {span: 11, offset: 0},
        },
        parameterArr: [
          {
            type: "input",
            ver: true,
            required: true,
            field: "word",
            name: "违禁词",
            reg: /^[\S\s]{1,64}$/,
            verMessage: "1-64个字符",
            option:{
              maxLength: 64,
              defaultValue: data?.word
            },
          },
        ],
      },
      {
        url: APILXD.editSenWord,
        otherParam: {
          _id: data._id,
        },
        beforeSubmit: (param) => {


          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }

  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '违禁词',
        dataIndex: 'word',
        key: 'word',
      },
      {
        title: '添加/更新时间',
        dataIndex: 'addtime',
        key: 'addtime',
        render:(text,record)=>{
          return <div>{TimeHelp.getYMDHM(record.updatetime || record.addtime)}</div>
        }
      },
      {
        title: '操作',
        width: 100,
        render: (text, record, index) => {
          return (<div>
            {
              this.powerConfig.Edit
                ?(  <div
                  className={less.btnEdit}
                  onClick={() => {
                    this.editWords(record)
                  }}
                >编辑
                </div>)
                :null
            }
          </div>)
        }
      },
    ]
  }
}

module.exports = SenWords;