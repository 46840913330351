//keyArray里定义所有会用到的权限类型，treeData里的name值必须从这里选，否则匹配不到
export default {
    keyArray:[
        {
            key: "Query",
            desc: "查询权限，比如查询列表，查询详情",
        },
        {
            key: "New",
            desc: "新增权限，比如列表右上角的新增按钮",
        },
        {
            key: "Edit",
            desc: "编辑权限，比如列表操作栏的编辑，禁用等",
        },
        {
            key: "Delete",
            desc: "删除权限，比如列表操作栏的删除",
        },
        {
            key: "Developer",
            desc: "开发者权限，（属于自定义）",
        },
        {
            key: "Special",
            desc: "特殊权限，（属于自定义）",
        },
        {
            key: "QueryAllNews",
            desc: "查询全部",
        },
    ],
    treeData:[
        {
            title: '通用权限',
            key: 'Others',
            hide: true, //这个权限不需要选择，设置为隐藏(比如查询个人信息，修改密码等)
            children: [
                {
                    title: '查询',
                    name: 'Query',
                    permit: true,
                    key: 'OthersQuery',
                    url: '/admin/admin/getMyinfo,/admin/admin/modfiyPasswd,/admin/admin/logoutAdmin,/admin/role/getAllRole,/admin/admin/modfiyName'
                },
                {
                    title: '编辑',
                    name: 'Edit',
                    permit: true,
                    key: 'OthersEdit',
                    url: '/admin/common/getUploadToken,/common/upload'
                },
            ]
        },
        {
            title: '新闻管理',
            key: 'NewsManage',
            children: [
                {
                    title: '查看自己的新闻',
                    name: 'Query',
                    permit: true,
                    key: 'NewsManageQuery',
                    url: '/admin/news/getNewsList,/admin/news/getNews'
                },
                {
                    title: '查看所有新闻',
                    name: 'QueryAllNews',
                    permit: true,
                    key: 'NewsManageQueryAllNews',
                    url: '/admin/news/getNewsList,/admin/news/getNews,/admin/news/getAllNewsList'
                },
                {
                    title: '创建新闻',
                    name: 'New',
                    permit: true,
                    key: 'NewsManageNew',
                    url: '/admin/news/addNews'
                },
                {
                    title: '编辑/发布新闻',
                    key: 'NewsManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/admin/news/editNews,/admin/news/publishNews,/admin/news/unPublishNews'
                },
                {
                    title: '删除新闻',
                    name: 'Delete',
                    permit: true,
                    key: 'NewsManageDelete',
                    url: '/admin/news/delNews'
                }
            ]
        },
        {
            title: '用户管理',
            key: 'UserManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'UserManageQuery',
                    url: '/admin/user/getUserList'
                },
                {
                    title: '编辑用户',
                    key: 'UserManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/admin/user/setUserStatus,/admin/user/resetUserPasswd'
                },
            ]
        },
        {
            title: '商家管理',
            key: 'BusinessManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'BusinessManageQuery',
                    url: '/admin/merchant/getMerchantList'
                },
                {
                    title: '创建商家',
                    name: 'New',
                    permit: true,
                    key: 'BusinessManageNew',
                    url: '/admin/merchant/addMerchant'
                },
                {
                    title: '编辑商家',
                    key: 'BusinessManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/admin/merchant/editMerchant,/admin/merchant/resetMerchantPasswd,/admin/merchant/enableMerchant,/admin/merchant/setMerchantYunXin'
                },
                {
                    title: '删除商家',
                    name: 'Delete',
                    permit: true,
                    key: 'BusinessManageDelete',
                    url: '/admin/merchant/delMerchant'
                }
            ]
        },
        {
            title: '发现管理',
            key: 'DiscoverManage',
            children: [
                {
                    title: '查看用户发布内容',
                    name: 'Query',
                    permit: true,
                    key: 'DiscoverManageQuery',
                    url: '/admin/discover/getAllDiscoverList,/admin/discover/getMDiscover'
                },
                {
                    title: '设置发现状态',
                    key: 'DiscoverManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/admin/discover/setDiscoverStatus'
                },
            ]
        },
        {
            title: '违禁词管理',
            key: 'SenWordsManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'SenWordsManageQuery',
                    url: '/admin/sensitiveWords/getSenGroupList,/admin/sensitiveWords/getSenGroupWordsList,/admin/sensitiveWords/getAllSenGroup'
                },
                {
                    title: '添加违禁词',
                    name: 'New',
                    permit: true,
                    key: 'SenWordsManageNew',
                    url: '/admin/sensitiveWords/addSenGroup,/admin/sensitiveWords/addSenWords'
                },
                {
                    title: '编辑违禁词',
                    key: 'SenWordsManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/admin/sensitiveWords/editSenGroup,/admin/sensitiveWords/editSenWord'
                },
                {
                    title: '删除违禁词',
                    name: 'Delete',
                    permit: true,
                    key: 'SenWordsManageDelete',
                    url: '/admin/sensitiveWords/delSenGroup,/admin/sensitiveWords/delSenWords,/admin/sensitiveWords/emptySenGroupWords'
                }
            ]
        },
        {
            title: '举报反馈管理',
            key: 'ReportMsgManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'ReportMsgManageQuery',
                    url: '/admin/reports/getReportList'
                },
                {
                    title: '处理',
                    key: 'ReportMsgManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/admin/reports/solveReport'
                },
                // {
                //     title: '删除',
                //     name: 'Delete',
                //     permit: true,
                //     key: 'ReportMsgManageDelete',
                //     url: ''
                // }
            ]
        },
        {
            title: '系统账号管理',
            key: 'AccountManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'AccountManageQuery',
                    url: '/admin/admin/getAdminList'
                },
                {
                    title: '创建系统账号',
                    name: 'New',
                    permit: true,
                    key: 'AccountManageNew',
                    url: '/admin/admin/addAdmin'
                },
                {
                    title: '编辑系统账号',
                    key: 'AccountManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/admin/admin/editAdmin,/admin/admin/getAdminDetail,/admin/admin/enableAdmin,/admin/admin/resetPasswd,/admin/admin/setAdCode'
                },
                {
                    title: '删除系统账号',
                    name: 'Delete',
                    permit: true,
                    key: 'AccountManageDelete',
                    url: '/admin/admin/delAdmin'
                }
            ]
        },
        {
            title: '权限管理：角色',
            key: 'RoleManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'RoleManageQuery',
                    url: '/admin/role/getRole,/admin/role/getRoleList'
                },
                {
                    title: '创建角色',
                    name: 'New',
                    permit: true,
                    key: 'RoleManageNew',
                    url: '/admin/role/addRole'
                },
                {
                    title: '编辑角色',
                    key: 'RoleManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/admin/role/editRole'
                },
                {
                    title: '删除角色',
                    name: 'Delete',
                    permit: true,
                    key: 'RoleManageDelete',
                    url: '/admin/role/delRole'
                }
            ]
        }
    ]
};
