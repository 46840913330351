import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Modal from 'antd/lib/modal';
import 'antd/lib/modal/style';
import less from "./style/index.less";
import ConfigProvider from "antd/lib/config-provider"
import zhCN from 'antd/lib/locale/zh_CN';

let id = "layer_modal";

class Index extends Component {
    constructor(props) {
        super(props);
    }

    show(props = {}, content, ids) {
        ids ? id = ids : id = "layer_modal"
        let div = document.getElementById(id)
        if (!div) {
            div = document.createElement('div');
            div.id = id;
            div.style.position = "fixed";
            div.style.zIndex = props.zIndex || "1009";
            document.body.appendChild(div);
        }
        ReactDOM.render(
          <ConfigProvider locale={zhCN}>
              <Content
                ref={(ref) => {
                    this.content = ref;
                }}
                removeDiv={() => {
                    //删除
                    try {
                        document.body.removeChild(div);
                    } catch (e) {
                        //
                    }

                }}
                {...props}
              >
                  {content}
              </Content>
          </ConfigProvider>,
            div
        );
    }

    close(modalId) {
        if (modalId) {
            let div = document.getElementById(modalId)
            if (div) {
                document.body.removeChild(div);
            }

            return;
        }
        if (this.content) {
            this.content.close();
        } else {
            let div = document.getElementById(id)
            if (div) {
                document.body.removeChild(div);
            }

        }
    }
}

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true
        }
    }

    close() {
        this.setState({
            visible: false
        }, () => {
            this.props.removeDiv()
        });
    }

    render() {

        return (
            <div className={less.show} ref={"test"}>
                <div ref={"modal"}>
                    <Modal
                      {...this.props}
                      getContainer={() => {
                          return this.refs.modal;
                      }}
                      visible={this.state.visible}
                      onCancel={() => {
                          this.setState({
                              visible: !this.state.visible
                          }, () => {
                              this.props.removeDiv()
                          });

                      }}
                      afterClose={() => {
                          this.props.removeDiv()
                      }}
                    >
                        {this.props.children}
                    </Modal>
                </div>

            </div>
        );
    }
}


export default new Index();