export default {
  publicKey:
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCF3cbHgviK4mr3++zjXsDKq9v5\n" +
    "GYW4jbPsbhUh69CVzEuqj8Xl0A/9XQPDxkofW1MnxtlaLt7QMsLRZBOyGEvla09n\n" +
    "Fb1ZaW6an6bTAoLaQOnHRnLENxItU9GCNKU/638czmZoxCOjfm/P5KSo0n2PdkFZ\n" +
    "6DQTwC5Mwz7X3Gau0QIDAQAB\n" +
    "-----END PUBLIC KEY-----", //HttpTools 加密公钥；
  projectUniqueName: "PPS_SYS_ADMIN",//项目cookies存储用
  requestTimeout: 60000,  //接口请求超时(ms)
};
