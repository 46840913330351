import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './RoleManage.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import TimeHelp from "../../../tool/TimeHelp";
const ModalDelete = new ModalDel();

class RoleList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getRoleList,
                otherParam: null,
            },
            search: {
                option: {
                    placeholder: '输入角色名称搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        return true;
                    }
                }
            },
            new: {
                defaultValue: '创建新角色',
                action: () => {
                    this.props.openTab({
                        path: 'AddRole',
                        title: '新建角色',
                        post: {
                            parent: this.props
                        }
                    })
                }
            }
        }
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '角色名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '创建人',
                dataIndex: 'admin',
                key: 'admin',
                render:(text,record)=>{
                    return record?.admin?.name || record?.admin?._id || "*"
                }
            },
            {
                title: '创建时间',
                dataIndex: 'addtime',
                key: 'addtime',
                render:(text,record)=>{
                    return <div>{TimeHelp.getYMDHM(record.addtime - 5000,true)}</div>
                }
            },
            {
                title: '操作',
                width: 250,
                render: (text, record, index) => {
                    return (<div>
                        {
                            this.powerConfig.Edit
                                ?(  <div
                                    className={less.btnEdit}
                                    onClick={() => {
                                        this.props.openTab({
                                            path: 'AddRole',
                                            title: '编辑角色',
                                            post: {
                                                id: record._id,
                                                parent: this.props
                                            }
                                        })
                                    }}
                                >编辑
                                </div>)
                                :null
                        }
                        {
                            this.powerConfig.Delete
                            ?(<div
                                    className={less.btnDelete}
                                    onClick={() => {
                                        ModalDelete.show({
                                                title: "提示",
                                                okTitle: "确定",
                                                closeTitle: "取消",
                                            },
                                            {}
                                            ,
                                            {
                                                otherParam: {_id: record._id},
                                                content: record.name,
                                                url: APILXD.delRole,
                                                apiType: 'post',
                                                tip: '删除后将无法恢复，请谨慎操作',
                                                callBack: (state) => {
                                                    //删除成功回调
                                                    state === "success" && this._loadDataForNet();
                                                }
                                            });
                                    }}
                                >删除
                                </div>)
                            :null
                        }
                    </div>)
                }
            },
        ]
    }
}

module.exports = RoleList;