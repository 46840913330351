import React from "react";
import TimeHelp from "../../../tool/TimeHelp";
import {Button, message, Modal, Popover, Descriptions, Spin} from "antd";
import HttpTool from "../../../tool/HttpTool";
import less from './DiscoverManage.less';
import APILXD from '../../../http/APILXD.js';

export default class DiscoverDetail extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: null
    }
  }

  componentDidMount() {
    this.getData()
  }


  getData(){
    let param = {
      _id:this.props.post._id,
    };
    let successCB = (code,msg,json,option)=>{
      this.setState({
        loading:false,
        data: json
      });
    };
    let failureCB = (code,msg)=>{
      this.setState({loading:false},()=>{
        message.error(msg);
      });
    };

    this.setState({loading:true});

    HttpTool.post(APILXD.getMDiscover,successCB,failureCB,param);
  }


  changeStatus(status){
    let param = {
      _id:this.props.post._id,
      status,
    };
    let successCB = (code,msg,json,option)=>{
      this.setState({loading:false},()=>{
        message.success('操作成功');
        this.getData();
      });
    };
    let failureCB = (code,msg)=>{
      this.setState({loading:false},()=>{
        message.error(msg);
      });
    };

    this.setState({loading:true});

    HttpTool.post(APILXD.setDiscoverStatus,successCB,failureCB,param);
  }

  render(){
    let {data} = this.state;

    if(!data){
      return <Spin/>
    }

    return <div>
      <Descriptions title="发现动态详情" bordered column={1} labelStyle={{width: "200px", textAlign: "right"}}>
        <Descriptions.Item label="发现标题">{data.title}</Descriptions.Item>
        <Descriptions.Item label="发现内容">{data.content}</Descriptions.Item>
        <Descriptions.Item label="所属标签">{data.tag}</Descriptions.Item>
        <Descriptions.Item label="点赞数">{data.likes}</Descriptions.Item>
        <Descriptions.Item label="用户名">{data.name}</Descriptions.Item>
        <Descriptions.Item label="用户id">{data.uid}</Descriptions.Item>
        <Descriptions.Item label="创建时间">{TimeHelp.getYMDHMS(data.addtime)}</Descriptions.Item>
        <Descriptions.Item label="配图">{
          data.imgs?.length
            ?(data.imgs.map((url,index)=>{
              return <div className={less.imgBox}
                          key={"i"+ index}
                          style={{
                            backgroundImage: `url(${url})`
                          }}
              >

              </div>
            }))
            :"无"

        }</Descriptions.Item>
        <Descriptions.Item label="当前状态">
          {
            {
              1: <span style={{color: "limegreen"}}>正常显示</span>,
              2: <span style={{color: "red"}}>已屏蔽</span>,
            }[data.status]
          }
        </Descriptions.Item>
        <Descriptions.Item label="操作">
          {
            data.status === 1
              ?<Button
                danger={true}
                type={"primary"}
                onClick={()=>{

                  Modal.confirm({
                    title:<div>确定 <span style={{color:'red'}}>屏蔽展示</span> 这条发现动态 吗？</div>,
                    content:<div>
                      设置为<span style={{color: "red"}}>屏蔽展示</span>后，App端用户将无法看到该动态。
                    </div>,
                    onOk:()=>{this.changeStatus(2)}
                  })
                }}
              >
                设置屏蔽
              </Button>
              :<Button
                type={"primary"}
                onClick={()=>{

                  Modal.confirm({
                    title:<div>确定 <span style={{color:'limegreen'}}>正常展示</span> 这条发现动态 吗？</div>,
                    content:<div >
                      设置为<span style={{color: "limegreen"}}>正常展示</span>后，App端用户可以看到该动态。
                    </div>,
                    onOk:()=>{this.changeStatus(1)}
                  })
                }}
              >
                设置展示
              </Button>
          }
        </Descriptions.Item>
      </Descriptions>
    </div>
  }
}