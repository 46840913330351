import React from 'react';
import less from './RoleManage.less';
import {Spin,Button,message,Row,Col,Modal,Tree,Space} from 'antd';
import APILXD from '../../../http/APILXD.js';
import HttpTool from "../../../tool/HttpTool";
import Layout from "../../../components/layout/index"
const ConfigData = require('../../leftMenu/PermissionConfig.js');

const treeData = ConfigData.treeData;
const TreeNode = Tree.TreeNode;


class AddRole extends React.Component{
    constructor(props){
        super(props);

        this.id = this.props.post.id;
        this.state = {
            loading:false,
            selected:[],
            defaultData:{},
            upDate:0,
            ifRefresh:false,    //是否重置密码
        }
    }

    componentDidMount(){
        if(this.id){
            this.loadData(this.id);
        }
    }

    render(){
        let data = this.state.defaultData;
        return (<div className={less.mainPage}>
            <Spin size={'large'} spinning={this.state.loading}>
                <div className={less.formContainer}>
                    <Layout
                        key={'layout'+this.state.upDate}
                        ref={(ref)=>{
                            this.baseMsgLayout = ref;
                        }}
                        {
                            ...this.getConfig(data)
                        }
                    />
                    {
                        this.id
                            ?(<Row>
                                <Col span={24} offset={4}>
                                    <Button
                                        size={'large'}
                                        type={'primary'}
                                        onClick={()=>{
                                            Modal.confirm({
                                                title:'提示',
                                                content:'重置密码为 123456 ？',
                                                onOk:()=>{
                                                    let defaultData = this.state.defaultData;
                                                    defaultData.passwd = '123456';
                                                    this.setState({
                                                        defaultData:defaultData,
                                                        ifRefresh:true,
                                                        upDate:this.state.upDate+1,
                                                    },()=>{
                                                        this.clickAddBtn();
                                                    });
                                                }
                                            })
                                        }}
                                    >重置密码</Button>
                                </Col>
                            </Row>)
                            :null
                    }
                    <Row>
                        <Col span={3} offset={1}>
                            <div>&nbsp;</div>
                            <div>&nbsp;</div>
                            <span className={less.label}>
                                权限设定：
                                 <Space>
                                <Button
                                  size={"small"}
                                  type={"primary"}
                                  onClick={()=>{
                                      let sel = this.getPower(treeData);
                                      this.setState({
                                          selected: sel
                                      })
                                  }}
                                >勾选所有</Button>
                                <Button
                                  size={"small"}
                                  danger={true}
                                  onClick={()=>{
                                      this.setState({
                                          selected: []
                                      })
                                  }}
                                >撤销勾选</Button>
                            </Space>
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} offset={1}>
                            <div className={less.treeBox}>
                                <Tree
                                    checkable={true}
                                    autoExpandParent={true}
                                    defaultExpandAll={true}
                                    checkedKeys={this.state.selected}
                                    onCheck={(selectedKeys,e)=>{
                                        this.setState({
                                            selected:selectedKeys,
                                        })
                                    }}
                                >
                                    {this.renderTreeNodes(treeData)}
                                </Tree>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} offset={4}>
                            <Button
                                size={'large'}
                                className={less.cancelBtn}
                                onClick={()=>{
                                    this.props.openTab(this.props.post.parent);
                                    this.props.closeTab(this.props.targetKey);
                                }}
                            >返回</Button>
                            <Button
                              size={'large'}
                              className={less.submitBtn}
                              type={'primary'}
                              onClick={()=>{
                                  this.clickAddBtn();
                              }}
                            >{this.id?'确认编辑':'创建完成'}</Button>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </div>)
    }

    //点击创建完成按钮
    clickAddBtn(){
        let option = this.baseMsgLayout.getLayoutValue(true);
        if(option.error){
            message.warning('请完善表单信息');
            return;
        }
        if(this.state.selected.length<=0){
            message.warning('请设定权限');
            return;
        }
        console.log(this.state.selected)
        let data = option.parameter;

        let powerResult = this.setPower(_.cloneDeep(treeData),this.state.selected);


        let param = {
            name:data.name,
            permission:powerResult,
            newsManage: this.state.selected.indexOf("NewsManageQuery") !== -1?1:2
        };

        if(this.id){
            param._id = this.id;
        }

        this.doSubmit(param);
    }

    //根据组件值设置权限树
    setPower(treeData,selected){
        let newTree = [].concat(treeData);
        for(let obj of newTree){
            if (!obj.hide) {
                for(let child of obj.children){
                    if(selected.indexOf(child.key)!=-1){
                        child.permit = true;
                    }else{
                        child.permit = false;
                    }
                }
            }
        }

        return newTree;
    }

    //获取以选择的权限
    getPower(treeData){
        if(!treeData || !treeData.length){
            return [];
        }

        let result = [];
        for(let obj of treeData){
            for(let child of obj.children){
                if(child.permit){
                    result.push(child.key)
                }
            }
        }

        return result;
    }

    //提交数据
    doSubmit(data){
        let param = data;
        log(data)
        let successCB = (code,msg,json,option)=>{
            this.setState({loading:false},()=>{
                message.success('操作成功');
                this.props.openTab(this.props.post.parent);
                this.props.closeTab(this.props.targetKey);
            });
        };
        let failureCB = (code,msg)=>{
            this.setState({loading:false},()=>{
                message.error(msg);
            });
        };

        this.setState({loading:true});
        let url = this.id?APILXD.editRole:APILXD.addRole;
        HttpTool.post(url,successCB,failureCB,param);
    }

    //请求数据
    loadData(id){
        if(!id){
            return
        };

        let param = {
            _id:this.id,
        };
        let successCB = (code,msg,json,option)=>{
            let permission = json && json.permission || [];
            let sel = this.getPower(permission);
            this.setState({
                loading:false,
                defaultData:json,
                selected:sel,
                upDate:this.state.upDate+1,
            });
        };
        let failureCB = (code,msg)=>{
            this.setState({loading:false},()=>{
                message.error(msg);
            });
        };

        this.setState({loading:true},()=>{
            HttpTool.post(APILXD.getRole,successCB,failureCB,param);
        });
    }

    //产生树节点
    renderTreeNodes(data){
        return data.map((item) => {
            if(item.hide){
                return null
            }
            if (item.children) {
                return (
                    <TreeNode className={less.big} title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode {...item} />;
        });
    }

    //配置
    getConfig(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: true,
        };
        return {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 4},
                wrapperCol: {span: 11, offset: 0},
            },
            parameterArr: [

                {
                    ...defaultOption,
                    field: "name",
                    name: "角色名称",
                    reg: /^[\S\s]{1,10}$/,
                    verMessage: "1-10个字符",
                    option:{
                        maxLength:'10',
                        defaultValue:data && data.name || undefined,
                    }
                },
            ],
        }
    }
}

module.exports = AddRole;