import React from "react"
import less from "./NewsManage.less"
import {Spin,Button,message,Steps,Modal,Form,Input,Radio,Checkbox,Switch} from "antd";
import WangEditView from "../../../components/wangEditView/WangEditView";
import HttpTool from "../../../tool/HttpTool";
import APILXD from "../../../http/APILXD"

export default class AddNews extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: null,
      current: 0,
    }

    this.osshost = null;
    this.imgMaps = {};
  }

  componentDidMount() {
    if(this.props.post?.id){
      this.getDetail()
    }
  }

  getDetail(){

    let successCB = (code,msg,json,option)=>{
      if(json.allowClose === 2){
        json.showtime = 1
      }

      // if(json.content){
      //   this.dealUrlFilter(json.content)
      // }

      this.baseInfo.setFieldsValue(json)
      this.editBox.setContent(json.content)
      this.setState({loading:false});
    };
    let failureCB = (code,msg)=>{
      this.setState({loading:false},()=>{
        Modal.error({
          title: "查询新闻出错",
          content: msg
        })
        this.setState({loading:false});
      });
    };

    this.setState({loading:true},()=>{

      HttpTool.post(APILXD.getNews,successCB,failureCB, {
        _id: this.props.post?.id
      });
    });
  }

  dealUrlFilter(content){
    if(!this.osshost){
      HttpTool.post(APILXD.getUploadToken,(code,msg,json)=>{
        this.osshost = json.host;
        this.dealUrlFilter(content)
      },(code,msg)=>{

      },{
        suffix: "png"
      })
    }else{
      let reg = /<[img|video].*?src=['"](.*?)['"]/g;
      let matchs = content.match(reg);
      if(matchs){
        let urls = matchs.map(url=> {
          return url.match(/\ssrc=['"](.*?)['"]/)[1]
        }).filter((url)=>{
          return url.indexOf(this.osshost) !== -1
        })
        console.log("urls",urls)

        urls.forEach((url)=>{
          this.imgMaps[url] = true;
        })
      }
    }
  }

  render(){
    let {loading,current,data} = this.state;

    return <div className={less.fullPage}>
      {
        loading
          ?<div className={less.layer}><Spin size={"large"}/></div>
          :null
      }
      <div className={less.head}>
        <Steps
          style={{width: "50%", margin: "0 auto"}}
          current={current}
          size={"small"}
          items={[
            {
              title: "基础信息",
            },
            {
              title: "新闻内容",
            }
          ]}
        />
      </div>
      <div className={less.body}>
        <div className={current===0?less.formContainer:less.formContainerHide}>
          <Form
            ref={(ref)=>{this.baseInfo = ref}}
            initialValues={data}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
          >
            <Form.Item
              name={"title"}
              label={"新闻标题"}
              rules={[{required: true}]}
            >
              <Input
                maxLength={30}
                placeholder="请填写新闻标题"
              />
            </Form.Item>
            <Form.Item
              name={"level"}
              label={"新闻类别"}
              rules={[{required: true}]}
              initialValue={1}
            >
              <Radio.Group
              >
                <Radio value={1}>日常新闻</Radio>
                <Radio value={2}>重大新闻</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={"effectime"}
              label={"时效"}
              rules={[{required: true}]}
              initialValue={1}
            >
              <Radio.Group
              >
                <Radio value={1}>一天</Radio>
                <Radio value={2}>三天</Radio>
                <Radio value={3}>七天</Radio>
                <Radio value={4}>一个月</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle={true}
              dependencies={["level"]}
            >
              {({getFieldValue})=>{
                let level = getFieldValue("level");

                if(level === 2){
                  //重大新闻
                  return <>
                    <Form.Item
                      name={"allowClose"}
                      label={"是否允许关闭"}
                      rules={[{required: true}]}
                      normalize={(value, prevValue, prevValues) => {
                        return value?1:2
                      }}
                      initialValue={1}
                      valuePropName={"checked"}
                      getValueProps={(value)=>{
                        return {
                          checked: value === 1,
                        }
                      }}
                    >
                      <Switch/>
                    </Form.Item>
                    <Form.Item
                      noStyle={true}
                      dependencies={["allowClose"]}
                    >
                      {({getFieldValue})=>{
                        let allowClose = getFieldValue("allowClose");

                        if(allowClose === 1){
                          return <Form.Item
                            name={"showtime"}
                            label={"显示时长"}
                            rules={[{required: true}]}
                            initialValue={1}
                          >
                            <Radio.Group
                            >
                              <Radio value={1}>可直接关闭</Radio>
                              <Radio value={2}>10秒可关闭</Radio>
                              <Radio value={3}>5分钟可关闭</Radio>
                            </Radio.Group>
                          </Form.Item>
                        }else{
                          return null
                        }
                      }}
                    </Form.Item>
                  </>
                }else{
                  //普通新闻
                  return null
                }
              }}
            </Form.Item>
            <Form.Item
              style={{marginTop: "150px"}}
              name={["sms","enable"]}
              label={"是否短信通知"}
              rules={[{required: true}]}
              normalize={(value, prevValue, prevValues) => {
                // console.log("enable1",value)
                return value?1:2
              }}
              initialValue={2}
              valuePropName={"checked"}
              getValueProps={(value)=>{
                return {
                  checked: value === 1,
                }
              }}
            >
              <Switch/>
            </Form.Item>
            <Form.Item
              noStyle={true}
              // dependencies={["sms","enable"]}
              shouldUpdate={()=>{return true}}
            >
              {({getFieldValue})=>{
                let enable = getFieldValue(["sms","enable"]);
                // console.log("enable2",enable)

                if(enable === 1){
                  //需要通知
                  return <>
                    <Form.Item
                      label={"短信模板"}
                    >
                     <div>
                       你有一条新消息
                       <Form.Item
                         name={["sms","title"]}
                         label={"短信标题"}
                         noStyle={true}
                         rules={[{required: true}]}
                       >
                        <Input
                          style={{width: "200px",marginLeft: "5px"}}
                          placeholder={"请填写短信提示语"}
                          maxLength={30}
                        />
                       </Form.Item></div>
                      <div>详情查看：xxxxxx</div>
                      <div>丰沃科技</div>
                    </Form.Item>
                  </>
                }else{
                  return null
                }
              }}
            </Form.Item>
          </Form>
        </div>
        <div className={current===1?less.formContainer:less.formContainerHide}>
          <WangEditView
            content={null}
            ref={(ref)=>{this.editBox = ref}}
            onUrlAdd={(url)=>{
              this.imgMaps[url] = true;
            }}
          />
        </div>
      </div>
      <div className={less.footer}>
        <Button
          onClick={()=>{
            Modal.confirm({
              title: "提示",
              content: "取消编辑不会保存任何修改，是否确定取消？",
              onOk: ()=>{
                this.props.closeTab(this.props.targetKey);
              }
            })
          }}
        >取消</Button>
        {
          current===1
            ?<Button
              type={"primary"}
              ghost={true}
              onClick={()=>{
                this.setState({
                  current: 0
                })
              }}
            >上一步</Button>
            :null
        }
        {
          current===0
            ?<Button
              type={"primary"}
              ghost={true}
              onClick={()=>{
                this.baseInfo.validateFields().then((values)=>{
                  this.setState({
                    current: 1
                  })
                }).catch((errorInfo)=>{

                })
              }}
            >下一步</Button>
            :<Button
              type={"primary"}
              onClick={()=>{
                this.dealSubmit()
              }}
            >完成</Button>
        }
      </div>
    </div>
  }

  dealSubmit(){
    this.baseInfo.validateFields().then((values)=>{
      let html = this.editBox.getContent();
      if(!html){
        message.warn("请填写新闻内容");
        return;
      }

      let params = _.cloneDeep(values);
      params.content = html;

      // let delFiles = [];
      // if(this.osshost){
      //   let reg = /<[img|video].*?src=['"](.*?)['"]/g;
      //   let matchs = html.match(reg);
      //   if(matchs){
      //     let urls = matchs.map(url=> {
      //       return url.match(/\ssrc=['"](.*?)['"]/)[1]
      //     }).filter((url)=>{
      //       return url.indexOf(this.osshost) !== -1
      //     })
      //     console.log("urls",urls)
      //
      //     Object.keys(this.imgMaps).forEach((url)=>{
      //       if(!urls.includes(url)){
      //         delFiles.push(url);
      //       }
      //     })
      //   }
      // }

      //新闻可能会复制，图片地址会被其它地方用到，不删了 -- 2023、11.22
      params.delFiles = [];


      if(!params.hasOwnProperty("allowClose")){
        params.allowClose = 1;
      }
      if(!params.hasOwnProperty("showtime")){
        params.showtime = 1;
      }

      if(this.props.post?.id){
        params._id = this.props.post.id
      }

      this.doSubmit(params)

    }).catch((err)=>{
      console.error(err)
      this.setState({
        current: 0
      })
    })
  }

  doSubmit(params){

    let successCB = (code,msg,json,option)=>{
      message.success(msg);

      this.props.closeTab(this.props.targetKey);
      this.props.openTab(this.props.post.parent);
    };
    let failureCB = (code,msg)=>{
      this.setState({loading:false},()=>{
        message.error(msg);
      });
    };

    this.setState({loading:true},()=>{

      let url = this.props.post?.id?APILXD.editNews:APILXD.addNews
      HttpTool.post(url,successCB,failureCB,params);
    });
  }
}