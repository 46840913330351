import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './BusinessManage.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import {Modal,message} from 'antd';
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index"
import HttpTool from "../../../tool/HttpTool";
import md5 from "md5";
const MB = new ModalBase();
const ModalDelete = new ModalDel();

class BusinessList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getMerchantList,
                otherParam: null,
            },
            search: {
                seniorSearch: this.getSeniorSearch(),
                option: {
                    openSenior: true,
                    placeholder: '输入关键词搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        return true;
                    }
                }
            },
            new: {
                defaultValue: '创建新账号',
                action: () => {
                    this.addNewAccount()
                }
            }
        }
    }

    getSeniorSearch(defaultData) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: false,
        };
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "status",
                    name: "状态",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        allowClear: true
                    },
                    data: [
                        {
                            title: <span style={{color: "limegreen"}}>启用</span>,
                            value: 1
                        },
                        {
                            title: <span style={{color: "red"}}>禁用</span>,
                            value: 2
                        },
                    ]
                }
            ],
        }
    }

    //创建、编辑账号
    addNewAccount(){
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[a-zA-Z0-9]{6,12}$/,
            verMessage: "6-12位数字或英文字母",
            required: true,
        };
        MB.show(
          {
              title: "创建商家",
              okTitle: "确定",
              closeTitle: "取消",
              width: "60%"
          },
          {
              colCount: 1,
              formItemLayout: {
                  labelCol: {span: 4},
                  wrapperCol: {span: 11, offset: 0},
              },
              parameterArr: [
                  {
                      ...defaultOption,
                      field: "name",
                      name: "商家名称",
                      reg: /^[\S\s]{1,128}$/,
                      verMessage: "1-128个字符",
                      option:{
                          maxLength: 128,
                      },
                  },
                  {
                      ...defaultOption,
                      field: "account",
                      name: "登录账号",
                      reg: /^[\S\s]{3,128}$/,
                      verMessage: "3-128个字符",
                      option:{
                          placeholder:'3-128个字符',
                          maxLength:'128',
                      }
                  },
                  {
                      ...defaultOption,
                      field: "passwd",
                      name: "密码",
                      reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
                      verMessage: "6-12位数字或英文字母或特殊符号",
                      option:{
                          placeholder:'6-12位数字或英文字母',
                          maxLength:'12',
                          type:'password',
                      }
                  },
                  {
                      ...defaultOption,
                      field: "checkword",
                      reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
                      name: "确认密码",
                      verMessage: "请核对密码",
                      option:{
                          placeholder:'再次输入密码',
                          maxLength:'12',
                          type:'password',
                      }
                  },
              ],
          },
          {
              url: APILXD.addMerchant,
              otherParam: {},
              beforeSubmit: (param) => {
                  if (param.passwd != param.checkword) {
                      message.warning('两次密码输入不一致，请检查');
                      return false;
                  }
                  //加密
                  param.passwd = md5(param.passwd);

                  return true;
              },
              callBack: (state, msg) => {
                  //添加成功回调
                  state == 'success' && this._loadDataForNet();
              }
          });
    }

    editAccount(data){
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[a-zA-Z0-9]{6,12}$/,
            verMessage: "6-12位数字或英文字母",
            required: true,
        };
        MB.show(
          {
              title: "编辑商家",
              okTitle: "确定",
              closeTitle: "取消",
              width: "60%"
          },
          {
              colCount: 1,
              formItemLayout: {
                  labelCol: {span: 4},
                  wrapperCol: {span: 11, offset: 0},
              },
              parameterArr: [
                  {
                      ...defaultOption,
                      field: "name",
                      name: "商家名称",
                      reg: /^[\S\s]{1,128}$/,
                      verMessage: "1-128个字符",
                      option:{
                          maxLength: 10,
                          defaultValue: data.name
                      },
                  },
                  {
                      ...defaultOption,
                      field: "account",
                      name: "登录账号",
                      reg: /^[a-zA-Z0-9]{3,20}$/,
                      option:{
                          placeholder:'3-20位英文字符',
                          maxLength:'20',
                          defaultValue: data.account
                      }
                  },
              ],
          },
          {
              url: APILXD.editMerchant,
              otherParam: {
                  _id: data._id
              },
              beforeSubmit: (param) => {


                  return true;
              },
              callBack: (state, msg) => {
                  //添加成功回调
                  state == 'success' && this._loadDataForNet();
              }
          });
    }

    changePW(data){
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[a-zA-Z0-9]{6,12}$/,
            verMessage: "6-12位数字或英文字母",
            required: true,
        };
        MB.show(
          {
              title: "重置密码",
              okTitle: "确定",
              closeTitle: "取消",
              width: "60%"
          },
          {
              colCount: 1,
              formItemLayout: {
                  labelCol: {span: 4},
                  wrapperCol: {span: 11, offset: 0},
              },
              parameterArr: [
                  {
                      ...defaultOption,
                      field: "name",
                      name: "商家名称",
                      reg: /^[\S\s]{1,128}$/,
                      verMessage: "1-128个字符",
                      purpose: "see",
                      option:{
                          maxLength: 10,
                          defaultValue: data.name,
                      },
                  },
                  {
                      ...defaultOption,
                      field: "account",
                      purpose: "see",
                      name: "登录账号",
                      reg: /^[a-zA-Z]{3,20}$/,
                      option:{
                          placeholder:'3-20位英文字符',
                          maxLength:'20',
                          defaultValue: data.account,
                      }
                  },
                  {
                      ...defaultOption,
                      field: "passwd",
                      name: "密码",
                      reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
                      verMessage: "6-12位数字或英文字母或特殊符号",
                      option:{
                          placeholder:'6-12位数字或英文字母',
                          maxLength:'12',
                          type:'password',
                      }
                  },
                  {
                      ...defaultOption,
                      field: "checkword",
                      reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
                      name: "确认密码",
                      verMessage: "请核对密码",
                      option:{
                          placeholder:'再次输入密码',
                          maxLength:'12',
                          type:'password',
                      }
                  },
              ],
          },
          {
              url: APILXD.resetMerchantPasswd,
              otherParam: {_id: data._id},
              beforeSubmit: (param) => {
                  if (param.passwd != param.checkword) {
                      message.warning('两次密码输入不一致，请检查');
                      return false;
                  }
                  //加密
                  param.passwd = md5(param.passwd);

                  return true;
              },
              callBack: (state, msg) => {
                  //添加成功回调
                  state == 'success' && this._loadDataForNet();
              }
          });
    }

    changeAccountStatus(id,status){
        let param = {
            _id:id,
            status,
        };
        let successCB = (code,msg,json,option)=>{
            this.setState({loading:false},()=>{
                message.success('操作成功');
                this._loadDataForNet();
            });
        };
        let failureCB = (code,msg)=>{
            this.setState({loading:false},()=>{
                message.error(msg);
            });
        };

        this.setState({loading:true});

        HttpTool.post(APILXD.enableMerchant,successCB,failureCB,param);
    }


    openMessage(record){
        let param = {
            _id:record._id,
        };
        let successCB = (code,msg,json,option)=>{
            this.setState({loading:false},()=>{
                message.success('操作成功');
                this._loadDataForNet();
            });
        };
        let failureCB = (code,msg)=>{
            this.setState({loading:false},()=>{
                message.error(msg);
            });
        };

        this.setState({loading:true});

        HttpTool.post(APILXD.setMerchantYunXin,successCB,failureCB,param);
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '商家名称',
                dataIndex: 'name',
                key: 'name',
                render:(text,record)=>{
                    return <div className={less.limitLabelBox}>{text}</div>
                }
            },
            {
                title: '用户名',
                dataIndex: 'account',
                key: 'account',
                render:(text,record)=>{
                    return <div className={less.limitLabelBox}>{text}</div>
                }
            },
            {
                title: '创建人',
                dataIndex: 'admin',
                key: 'admin',
                render:(text,record)=>{
                    return record?.admin?.name || record?.admin?._id || "*"
                }
            },
            {
                title: '创建时间',
                dataIndex: 'addtime',
                key: 'addtime',
                render:(text,record)=>{
                    return <div>{TimeHelp.getYMDHM(record.addtime)}</div>
                }
            },
            {
                title: '消息',
                dataIndex: 'accid',
                key: 'accid',
                render:(text)=>{
                    return text?<span style={{color:'#333'}}>开</span>:<span style={{color:'#aaa'}}>关</span>
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render:(text)=>{
                    return text==1?<span style={{color:'limegreen'}}>启用</span>:<span style={{color:'red'}}>禁用</span>
                }
            },
            {
                title: '操作',
                width: 350,
                render: (text, record, index) => {
                    return (<div>
                        {
                            this.powerConfig.Edit
                                ?(  <div
                                    className={record.status==1?less.btnDelete:less.btnEdit}
                                    onClick={() => {
                                        let newStatus = record.status==1?2:1;

                                        if(newStatus === 1){
                                            Modal.confirm({
                                                title:<div>确定 <span style={{color:'limegreen'}}>启用</span> "{record.name}" 吗？</div>,
                                                content:<div style={{color: "red"}}>
                                                    启用后该商家账号将正常使用，请谨慎操作。
                                                </div>,
                                                onOk:()=>{this.changeAccountStatus(record._id,newStatus)}
                                            })
                                        }else{
                                            Modal.confirm({
                                                title:<div>确定 <span style={{color:'red'}}>禁用</span> "{record.name}" 吗？</div>,
                                                content:<div style={{color: "red"}}>
                                                    禁用后该商家账号将无法使用，请谨慎操作。
                                                </div>,
                                                onOk:()=>{this.changeAccountStatus(record._id,newStatus)}
                                            })
                                        }
                                    }}
                                >{record.status==1?'禁用':'启用'}
                                </div>)
                                :null
                        }
                        {
                            this.powerConfig.Edit && !record.accid
                              ?(  <div
                                className={less.btnEdit}
                                onClick={() => {
                                    this.openMessage(record)
                                }}
                              >开启消息
                              </div>)
                              :null
                        }
                        {
                            this.powerConfig.Edit
                                ?(  <div
                                    className={less.btnEdit}
                                    onClick={() => {
                                        this.editAccount(record)
                                    }}
                                >编辑
                                </div>)
                                :null
                        }
                        {
                            this.powerConfig.Edit
                              ?(  <div
                                className={less.btnEdit}
                                onClick={() => {
                                    this.changePW(record)
                                }}
                              >重置密码
                              </div>)
                              :null
                        }
                        {
                            this.powerConfig.Delete
                            ?(<div
                                    className={less.btnDelete}
                                    onClick={() => {
                                        ModalDelete.show({
                                                title: "提示",
                                                okTitle: "确定",
                                                closeTitle: "取消",
                                            },
                                            {}
                                            ,
                                            {
                                                otherParam: {_id: record._id},
                                                content: record.name,
                                                url: APILXD.delMerchant,
                                                apiType: 'post',
                                                tip: '删除后将无法恢复，请谨慎操作',
                                                callBack: (state) => {
                                                    //删除成功回调
                                                    state === "success" && this._loadDataForNet();
                                                }
                                            });
                                    }}
                                >删除
                                </div>)
                            :null
                        }
                    </div>)
                }
            },
        ]
    }
}

module.exports = BusinessList;