let APILXD = {
    /**
     * 通用
     */
    //上传文件
    upLoad: '/common/upload',
    //获取上传文件的token(阿里，七牛)
    getUploadToken: '/admin/common/getUploadToken',


    /**
     * 后台账号管理
     */
    //登陆
    login: '/admin/admin/login',
    //获取密码随机串
    getRandSalt: '/admin/admin/getRandSalt',
    //后台账号列表
    getAdminList: '/admin/admin/getAdminList',
    //新增账号
    addAdmin: '/admin/admin/addAdmin',
    //查询账号详情
    getMyinfo: '/admin/admin/getMyinfo',
    //编辑账号
    editAdmin: '/admin/admin/editAdmin',
    //删除账号
    delAdmin: '/admin/admin/delAdmin',
    //退出登陆
    logoutAdmin: '/admin/admin/logoutAdmin',
    //修改密码
    modfiyPasswd: '/admin/admin/modfiyPasswd',
    //启用、禁用管理员
    enableAdmin: '/admin/admin/enableAdmin',
    //修改密码
    resetPasswd: '/admin/admin/resetPasswd',
    //修改名称
    modfiyName: '/admin/admin/modfiyName',
    //设置区域编码
    setAdCode: '/admin/admin/setAdCode',

    /**
     * 角色管理
     */
    addRole: '/admin/role/addRole',
    editRole: '/admin/role/editRole',
    getRole: '/admin/role/getRole',
    delRole: '/admin/role/delRole',
    getRoleList: '/admin/role/getRoleList',
    getAllRole: '/admin/role/getAllRole',

    /**
     * 商家管理
     */
    addMerchant: '/admin/merchant/addMerchant',
    editMerchant: '/admin/merchant/editMerchant',
    resetMerchantPasswd: '/admin/merchant/resetMerchantPasswd',
    delMerchant: '/admin/merchant/delMerchant',
    enableMerchant: '/admin/merchant/enableMerchant',
    getMerchantList: '/admin/merchant/getMerchantList',
    setMerchantYunXin: '/admin/merchant/setMerchantYunXin',

    /**
     * 用户管理
     */
    getUserList: '/admin/user/getUserList',
    setUserStatus: '/admin/user/setUserStatus',
    resetUserPasswd: '/admin/user/resetUserPasswd',

    /**
     * 新闻管理
     */
    getNewsList: '/admin/news/getNewsList',
    addNews: '/admin/news/addNews',
    editNews: '/admin/news/editNews',
    delNews: '/admin/news/delNews',
    publishNews: '/admin/news/publishNews',
    unPublishNews: '/admin/news/unPublishNews',
    getNews: '/admin/news/getNews',
    getAllNewsList: '/admin/news/getAllNewsList',

    /**
     * 违禁词管理
     */
    getSenGroupList: '/admin/sensitiveWords/getSenGroupList',
    addSenGroup: '/admin/sensitiveWords/addSenGroup',
    editSenGroup: '/admin/sensitiveWords/editSenGroup',
    delSenGroup: '/admin/sensitiveWords/delSenGroup',
    getSenGroupWordsList: '/admin/sensitiveWords/getSenGroupWordsList',
    addSenWords: '/admin/sensitiveWords/addSenWords',
    editSenWord: '/admin/sensitiveWords/editSenWord',
    delSenWords: '/admin/sensitiveWords/delSenWords',
    emptySenGroupWords: '/admin/sensitiveWords/emptySenGroupWords',
    getAllSenGroup: '/admin/sensitiveWords/getAllSenGroup',

    //举报
    getReportList: '/admin/reports/getReportList',
    solveReport: '/admin/reports/solveReport',

    //发布
    getAllDiscoverList: '/admin/discover/getAllDiscoverList',
    getMDiscover: '/admin/discover/getMDiscover',
    setDiscoverStatus: '/admin/discover/setDiscoverStatus',
};

module.exports = APILXD;
