import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './UserManage.less';
import APILXD from '../../../http/APILXD.js';
import {Modal,message} from 'antd';
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index"
import HttpTool from "../../../tool/HttpTool";
import md5 from "md5";
const MB = new ModalBase();

class UserList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getUserList,
                otherParam: null,
            },
            search: {
                seniorSearch: this.getSeniorSearch(),
                option: {
                    openSenior: true,
                    placeholder: '输入关键词搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        console.log("看看数据",value)
                        return true;
                    }
                }
            },
        }
    }

    getSeniorSearch(defaultData) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: false,
        };
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "status",
                    name: "状态",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        allowClear: true
                    },
                    data: [
                        {
                            title: <span style={{color: "limegreen"}}>启用</span>,
                            value: 1
                        },
                        {
                            title: <span style={{color: "red"}}>禁用</span>,
                            value: 2
                        },
                    ]
                }
            ],
        }
    }

    changePW(data){
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[a-zA-Z0-9]{6,12}$/,
            verMessage: "6-12位数字或英文字母",
            required: true,
        };
        MB.show(
          {
              title: "重置密码",
              okTitle: "确定",
              closeTitle: "取消",
              width: "60%"
          },
          {
              colCount: 1,
              formItemLayout: {
                  labelCol: {span: 4},
                  wrapperCol: {span: 11, offset: 0},
              },
              parameterArr: [
                  {
                      ...defaultOption,
                      field: "name",
                      name: "用户名称",
                      reg: /^[\S\s]{1,10}$/,
                      verMessage: "1-10个字符",
                      purpose: "see",
                      option:{
                          maxLength: 10,
                          defaultValue: data.name,
                      },
                  },
                  {
                      ...defaultOption,
                      field: "passwd",
                      name: "密码",
                      reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
                      verMessage: "6-12位数字或英文字母或特殊符号",
                      option:{
                          placeholder:'6-12位数字或英文字母',
                          maxLength:'12',
                          type:'password',
                      }
                  },
                  {
                      ...defaultOption,
                      field: "checkword",
                      reg: /^[a-zA-Z0-9.~!@#$%^&*?_-]{6,12}$/,
                      name: "确认密码",
                      verMessage: "请核对密码",
                      option:{
                          placeholder:'再次输入密码',
                          maxLength:'12',
                          type:'password',
                      }
                  },
              ],
          },
          {
              url: APILXD.resetUserPasswd,
              otherParam: {_id: data._id},
              beforeSubmit: (param) => {
                  if (param.passwd != param.checkword) {
                      message.warning('两次密码输入不一致，请检查');
                      return false;
                  }
                  //加密
                  param.passwd = md5(param.passwd);

                  return true;
              },
              callBack: (state, msg) => {
                  //添加成功回调
                  state == 'success' && this._loadDataForNet();
              }
          });
    }

    changeAccountStatus(id,status){
        let param = {
            _id:id,
            status,
        };
        let successCB = (code,msg,json,option)=>{
            this.setState({loading:false},()=>{
                message.success('操作成功');
                this._loadDataForNet();
            });
        };
        let failureCB = (code,msg)=>{
            this.setState({loading:false},()=>{
                message.error(msg);
            });
        };

        this.setState({loading:true});

        HttpTool.post(APILXD.setUserStatus,successCB,failureCB,param);
    }


    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '用户名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '注册时间',
                dataIndex: 'addtime',
                key: 'addtime',
                render:(text,record)=>{
                    return <div>{TimeHelp.getYMDHM(record.addtime,true)}</div>
                }
            },
            {
                title: '最近登录',
                dataIndex: 'logintime',
                key: 'logintime',
                render:(text,record)=>{
                    return <div>{TimeHelp.getYMDHM(record.addtime,true)}</div>
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render:(text)=>{
                    return text==1?<span style={{color:'limegreen'}}>启用</span>:<span style={{color:'red'}}>禁用</span>
                }
            },
            {
                title: '操作',
                width: 200,
                render: (text, record, index) => {
                    return (<div>
                        {
                            this.powerConfig.Edit
                                ?(  <div
                                    className={record.status==1?less.btnDelete:less.btnEdit}
                                    onClick={() => {
                                        let newStatus = record.status==1?2:1;

                                        if(newStatus === 1){
                                            Modal.confirm({
                                                title:<div>确定 <span style={{color:'limegreen'}}>启用</span> "{record.name}" 吗？</div>,
                                                content:<div style={{color: "red"}}>
                                                    启用后该用户账号将正常使用，请谨慎操作。
                                                </div>,
                                                onOk:()=>{this.changeAccountStatus(record._id,newStatus)}
                                            })
                                        }else{
                                            Modal.confirm({
                                                title:<div>确定 <span style={{color:'red'}}>禁用</span> "{record.name}" 吗？</div>,
                                                content:<div style={{color: "red"}}>
                                                    禁用后该用户账号将无法使用，请谨慎操作。
                                                </div>,
                                                onOk:()=>{this.changeAccountStatus(record._id,newStatus)}
                                            })
                                        }
                                    }}
                                >{record.status==1?'禁用':'启用'}
                                </div>)
                                :null
                        }
                        {
                            this.powerConfig.Edit
                              ?(  <div
                                className={less.btnEdit}
                                onClick={() => {
                                    this.changePW(record)
                                }}
                              >重置密码
                              </div>)
                              :null
                        }
                    </div>)
                }
            },
        ]
    }
}

module.exports = UserList;