import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './NewsManage.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import TimeHelp from "../../../tool/TimeHelp";
import {Modal, message, Space, Popover, Tag} from "antd"
import HttpTool from "../../../tool/HttpTool";
import {findFullCodes} from "../../help/LXDHelp";

const ModalDelete = new ModalDel();

class NewsList extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        columns: this.getTableColumns(),
        url: APILXD.getAllNewsList,
        otherParam: null,
      },
      search: {
        seniorSearch: this.getSeniorSearch(),
        option: {
          openSenior: true,
          placeholder: '输入关键词名称搜索',
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            return true;
          }
        }
      },
      new: {
        defaultValue: '创建新闻',
        action: () => {
          if(!window._adcode){
            Modal.info({
              title: "提示",
              content: "您的账号还未被赋予数据权限，请联系管理员"
            })
            return;
          }
          this.props.openTab({
            path: 'AddNews',
            title: '新建新闻',
            post: {
              parent: this.props
            }
          })
        }
      }
    }
  }


  getSeniorSearch(defaultData) {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[\S\s]{0,50}$/,
      verMessage: "最多50个字符",
      required: false,
    };
    return {
      colCount: 3,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 18, offset: 0},
      },
      parameterArr: [
        {
          ...defaultOption,
          field: "level",
          name: "新闻类别",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return true;
          },
          option: {
            allowClear: true
          },
          data: [
            {
              title: <span>日常新闻</span>,
              value: 1
            },
            {
              title: <span style={{color: "red"}}>重大新闻</span>,
              value: 2
            },
          ]
        }
      ],
    }
  }

  //发布
  publishNews(record) {
    HttpTool.post(APILXD.publishNews, (code, msg) => {
      message.success(msg)
      this._loadDataForNet()
    }, (code, msg) => {
      Modal.error({
        title: "发布出错",
        content: msg
      })
    }, {
      _id: record._id
    })
  }

  unPublishNews(record) {
    HttpTool.post(APILXD.unPublishNews, (code, msg) => {
      message.success(msg)
      this._loadDataForNet()
    }, (code, msg) => {
      Modal.error({
        title: "取消发布出错",
        content: msg
      })
    }, {
      _id: record._id
    })
  }


  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '地区',
        dataIndex: 'adcode',
        key: 'adcode',
        render: (txt, record) => {
          let full = findFullCodes(record.adcode)
          // console.log("full",full)
          let last = full[full.length - 1];

          return <Space>
            {
              last
                ? <Popover
                  title={"新闻覆盖地区"}
                  content={full.map((each) => {
                    return each.label
                  }).join("/")}
                >
                  <Tag
                    color={last.value === "100000" ? "magenta" : "blue"}
                  >{last.label}</Tag>
                </Popover>
                : null
            }
          </Space>
        }
      },
      {
        title: '新闻标题',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: '新闻类别',
        dataIndex: 'level',
        key: 'level',
        render: (text, record) => {
          return text === 1 ? <span style={{color: ""}}>日常新闻</span> : <span style={{color: "red"}}>重大新闻</span>
        }
      },
      {
        title: '过期时间',
        dataIndex: 'endtime',
        key: 'endtime',
        render: (text, record) => {
          if (record.status === 2) {
            return "-"
          }

          if (record.endtime < Date.now()) {
            return <span style={{color: "darkorange"}}>已过期</span>
          }

          return <div>{TimeHelp.getYMD(record.endtime)}</div>
        }
      },
      {
        title: '创建人',
        dataIndex: 'admin',
        key: 'admin',
        render: (text, record) => {
          return record?.admin?.name || record?.admin?._id || "*"
        }
      },
      {
        title: '创建时间',
        dataIndex: 'addtime',
        key: 'addtime',
        render: (text, record) => {
          return <div>{TimeHelp.getYMDHM(record.addtime - 5000, true)}</div>
        }
      },
      {
        title: '操作',
        width: 250,
        render: (text, record, index) => {
          return (<div>
            {
              this.powerConfig.Edit && record.status === 2
                ? (<div
                  className={less.btnAdd}
                  onClick={() => {
                    if(!window._adcode){
                      Modal.info({
                        title: "提示",
                        content: "您的账号还未被赋予数据权限，请联系管理员"
                      })
                      return;
                    }
                    Modal.confirm({
                      title: `确定发布 "${record.title}" 吗？`,
                      content: "发布后将同步至人民驿站APP，请谨慎操作",
                      onOk: () => {
                        this.publishNews(record)
                      }
                    })
                  }}
                >发布
                </div>)
                : null
            }
            {
              this.powerConfig.Edit && record.status === 1
                ? (<div
                  className={less.btnWarn}
                  onClick={() => {
                    if(!window._adcode){
                      Modal.info({
                        title: "提示",
                        content: "您的账号还未被赋予数据权限，请联系管理员"
                      })
                      return;
                    }
                    Modal.confirm({
                      title: `确定取消发布 "${record.title}" 吗？`,
                      content: "取消发布后人民驿站APP将不再显示，请谨慎操作",
                      onOk: () => {
                        this.unPublishNews(record)
                      }
                    })
                  }}
                >取消发布
                </div>)
                : null
            }
            {
              this.powerConfig.Edit && record.status === 2
                ? (<div
                  className={less.btnEdit}
                  onClick={() => {
                    if(!window._adcode){
                      Modal.info({
                        title: "提示",
                        content: "您的账号还未被赋予数据权限，请联系管理员"
                      })
                      return;
                    }
                    this.props.openTab({
                      path: 'AddNews',
                      title: '编辑新闻',
                      post: {
                        id: record._id,
                        parent: this.props
                      }
                    })
                  }}
                >编辑
                </div>)
                : null
            }
            {
              this.powerConfig.Delete && record.status === 2
                ? (<div
                  className={less.btnDelete}
                  onClick={() => {
                    if(!window._adcode){
                      Modal.info({
                        title: "提示",
                        content: "您的账号还未被赋予数据权限，请联系管理员"
                      })
                      return;
                    }
                    ModalDelete.show({
                        title: "提示",
                        okTitle: "确定",
                        closeTitle: "取消",
                      },
                      {}
                      ,
                      {
                        otherParam: {_id: record._id},
                        content: record.title,
                        url: APILXD.delNews,
                        apiType: 'post',
                        tip: '删除后将无法恢复，请谨慎操作',
                        callBack: (state) => {
                          //删除成功回调
                          state === "success" && this._loadDataForNet();
                        }
                      });
                  }}
                >删除
                </div>)
                : null
            }
          </div>)
        }
      },
    ]
  }
}

module.exports = NewsList;