import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './SenWordsManage.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index"
const MB = new ModalBase();
const ModalDelete = new ModalDel();

class SenWordsGroup extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        title: "违禁词分组",
        columns: this.getTableColumns(),
        url: APILXD.getSenGroupList,
        otherParam: null,
      },
      new: {
        defaultValue: '新增分组',
        action: () => {
          this.addNewGroup()
        }
      }
    }
  }

  addNewGroup(data){
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[a-zA-Z0-9]{6,12}$/,
      verMessage: "6-12位数字或英文字母",
      required: true,
    };
    MB.show(
      {
        title: data?"编辑分组":"新增分组",
        okTitle: "确定",
        closeTitle: "取消",
        width: "60%"
      },
      {
        colCount: 1,
        formItemLayout: {
          labelCol: {span: 4},
          wrapperCol: {span: 11, offset: 0},
        },
        parameterArr: [
          {
            ...defaultOption,
            field: "name",
            name: "分组名称",
            reg: /^[\S\s]{1,32}$/,
            verMessage: "1-32个字符",
            option:{
              maxLength: 32,
              defaultValue: data?.name
            },
          },
        ],
      },
      {
        url: data?APILXD.editSenGroup:APILXD.addSenGroup,
        otherParam: data?{_id: data._id}:{},
        beforeSubmit: (param) => {


          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }


  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '分组名称',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '违禁词数量',
        dataIndex: 'wordNum',
        key: 'wordNum',
      },
      {
        title: '添加/更新时间',
        dataIndex: 'addtime',
        key: 'addtime',
        render:(text,record)=>{
          return <div>{TimeHelp.getYMDHM(record.updatetime || record.addtime)}</div>
        }
      },
      {
        title: '操作',
        width: 350,
        render: (text, record, index) => {
          return (<div>
            {
              this.powerConfig.Edit
                ?(  <div
                  className={less.btnEdit}
                  onClick={() => {
                    this.addNewGroup(record)
                  }}
                >编辑分组
                </div>)
                :null
            }
            {
              this.powerConfig.Delete
                ?(<div
                  className={less.btnDelete}
                  onClick={() => {
                    ModalDelete.show({
                        title: "提示",
                        okTitle: "确定",
                        closeTitle: "取消",
                      },
                      {}
                      ,
                      {
                        otherParam: {_id: record._id},
                        content: record.name,
                        url: APILXD.delSenGroup,
                        apiType: 'post',
                        tip: '删除后将无法恢复，请谨慎操作',
                        callBack: (state) => {
                          //删除成功回调
                          state === "success" && this._loadDataForNet();

                          this.props.onDeleteGroup?.(record._id)
                        }
                      });
                  }}
                >删除分组
                </div>)
                :null
            }
            {
              this.powerConfig.Edit
                ?(  <div
                  className={less.btnEdit}
                  onClick={() => {
                    this.props.manageWords(record)
                  }}
                >管理违禁词->
                </div>)
                :null
            }
          </div>)
        }
      },
    ]
  }
}

module.exports = SenWordsGroup;